import * as React from "react";
import { useWatch } from "react-hook-form";
import isEmpty from "lodash/isEmpty"
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  useNotify,
  useDataProvider,
  NumberInput,
  useRefresh,
  SimpleForm,
  useRecordContext,
  Loading,
  Show,
  SimpleShowLayout,
  FunctionField,
} from 'react-admin'
import { Button } from "@mui/material";
import SendAutomatedEmailDialog from "../common/SendAutomatedEmailDialog";
import DisableReminder from "../common/DisableReminder";
import OpenEmailClient from "../common/OpenEmailClient";
import PreviewLink from "../../utils/PreviewLink";
import DownloadLink from "../../utils/DownloadLink";
import {
  showTimeRange,
  showAmountCurrencyAndCurrencyToProve,
  isAmountToProveFieldRequired,
  displaySurveyATPonShow,
  showInsights,
  showForIncome,
  showLargeItemInput,
  showFollowUpDataRangeInput
} from "../../utils/CreateReportUtils";
import { isPdfEnabled, positiveAmount } from "../../utils/util";
import CustomDialog from "../../utils/CustomDialog";
import { 
   isAdminUser,
   httpClient,
   isFeatureEnabled
} from "../DataProvider";
import {
  CURRENCY_SYMBOLS,
  TIME_RANGE_MONTHS,
  ACTIVE_REPORT_STATUSES,
  REPORT_TYPES_DISPLAY_NAMES,
  RESOURCES,
  SURVEY_TYPES,
  REPORT_STATUSES,
  RESOURCES_BASEPATH,
  EVENT_REASON,
  ActionTitles,
  ENABLED_FEATURES_ENUM,
} from '../Constants'
import { extractQueryParamsFromURL, showOpenEmailClientButton } from "../../mappers/Report";
import isUndefined from "lodash/isUndefined";
import { ExpandReportButton } from "./IncomeVerificationForCreateReportGrid";
import { useLocation } from "react-router";
import { ReportListItem } from '../common/listItems/ReportListItem'
import {
  baseButtonStyle,
  contentCenter,
  float,
  iconAction,
  margin1,
  padding0,
  padding1,
  padding2,
  textColor
} from '../Styles'
import ListActions from '../common/ListActions'
import CustomTooltip from '../../utils/CustomTooltip'

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const ReportShow = () => (
  <Show
    actions={
      <ListActions
        title={ActionTitles.REPORT_DETAILS}
        showDeleteButton={true}
        basePath={RESOURCES_BASEPATH.REPORTS}
        resource={RESOURCES.REPORTS}
        recordName={ActionTitles.REPORT}
      />
    }
    renderButtonAndSearch={false}
    sx={contentCenter}
  >
    <SimpleShowLayout>
      <ReportShowGrid />
    </SimpleShowLayout>
  </Show>
);

export const ReportShowGrid = () => {
  const record = useRecordContext();
  const location = useLocation();
  const notify = useNotify();
  const isAdmin = isAdminUser();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [surveys, setSurveys] = React.useState([])
  const [sofSurveys, setSofSurveys] = React.useState([])
  const [factFindSurveys, setFactFindSurveys] = React.useState([])
  const [isExpandedReportAvailable, setIsExpandedReportAvailable] = React.useState(false);  
  const [retryCount, setRetryCount] = React.useState(0);
  const expandReportEnabled = isFeatureEnabled(ENABLED_FEATURES_ENUM.EXPAND_REPORT);
  const expandedReportTimer = React.useRef(null);

  const isExpandedReport = extractQueryParamsFromURL(location?.search, 'expandedReport');

  const copyToClipboard = (textToCopy) => {
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;

    document.body.appendChild(textArea);
    textArea.select();

    var successful = document.execCommand("copy");
    document.body.removeChild(textArea);
    if (!successful) {
      notify("Failed to copy", { type: 'info', autoHideDuration: 1000 });
    } else {
      notify("Text copied to clipboard", { type: 'info', autoHideDuration: 1000 });
    }
  };

  const surveyResource = undefined;

  React.useEffect(() => {
    const checkExpandedReportAvailable = () => {      
      dataProvider.getOne(RESOURCES.REPORTS, { id: record.id })
        .then(({ data }) => {
          if (data.status === REPORT_STATUSES.AVAILABLE) {
            setIsExpandedReportAvailable(true);
            clearInterval(expandedReportTimer.current);
            refresh();
          }
        })
        setRetryCount(prevCount => prevCount + 1);
    }

    if(isExpandedReport){
      expandedReportTimer.current = setInterval(checkExpandedReportAvailable, process.env.REACT_APP_EXPANDED_REPORT_TIMEOUT*1000);

      return () => clearInterval(expandedReportTimer.current);  //To clear interval when component unmounts
    }    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  React.useEffect(() => {    
    if(retryCount > process.env.REACT_APP_EXPANDED_REPORT_RETRY_ATTEMPTS){
      clearInterval(expandedReportTimer.current);
      setIsExpandedReportAvailable(true);
    }
  },[retryCount, expandedReportTimer])

  React.useEffect(() => {
    if (!isEmpty(surveyResource)) {
      setSurveys(surveyResource)
      return
    }
    async function fetchSurveys() {
      await dataProvider.getList('survey', {
        pagination: 1,
        sort: 'DESC',
        filter: { clientId: record['clientId'] }
      })
      .then(async ({ data }) => {
          await setSurveys(data)
        })
        .catch(error => {
          console.log(error)
        })
    }
    fetchSurveys();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const surveyIds = record.surveyIds

    if (!surveyIds) return
    if ((Array.isArray(surveys) && !surveys.length) || (typeof survey === 'object' && isEmpty(surveys))) return

    
    async function mapSurveys() {
      let sofSurveyList = []
      let factFindSurveyList = []

      for (let id of surveyIds) {
        const survey = Array.isArray(surveys) ? surveys.find(survey => survey.id === id) : surveys[id]
        if (survey)  {
          survey.surveyType === SURVEY_TYPES.FACT_FIND ? factFindSurveyList.push(survey) : sofSurveyList.push(survey)
        } else {
          const surveyById = await httpClient(`${apiUrl}/survey/${id}`)
            .then(({ json }) => {
              return json.data
            })
            surveyById.surveyType === SURVEY_TYPES.FACT_FIND ? factFindSurveyList.push(surveyById) : sofSurveyList.push(surveyById)
        }
      }

      
      setFactFindSurveys(factFindSurveyList)
      setSofSurveys(sofSurveyList)
    }
    
    mapSurveys();
  }, [record.surveyIds, surveys]) // eslint-disable-line react-hooks/exhaustive-deps

  const isReportActive = ACTIVE_REPORT_STATUSES.includes(record["status"]);
  const requestSurveyParams = record["surveyParams"]
  const selectedSurveys = {
    "selectedSourceOfFundsSurvey" : sofSurveys.length > 0 ? sofSurveys[0] : undefined,
    "selectedFactFindSurvey" : factFindSurveys.length > 0 ? factFindSurveys[0] : undefined
  }
  
  if (isExpandedReport && !isExpandedReportAvailable)
    return (
      <Loading
        loadingPrimary="We are preparing your report"
        loadingSecondary="This might take a moment"
      />
    );

  return (
    <Grid container flexGrow={1}>
      <Grid item xs={12}>
        <List sx={padding0.y}>
          <ReportListItem title="Request Id" value={record['requestId']} />
          <Divider light />
          <ReportListItem title="Contact Name" value={record['contactName']} />
          <Divider light />
          <ReportListItem title="Email Address" value={record['emailAddress']} />
          <Divider light />
          <ReportListItem title="Business Name" value={record['businessName']} />
          <Divider light />
          <ReportListItem title="Description Of Matter" value={record['descriptionOfMatter']} />
          <Divider light />
          <ReportListItem title="Client Reference" value={record['clientReference']} />
          <Divider light />
          <ReportListItem title="Report Type" value={REPORT_TYPES_DISPLAY_NAMES[record['reportType']]} />
          <Divider light />
          <ReportListItem title="Status" value={record['status']} />
          <Divider light />
          {(record["status"] === REPORT_STATUSES.REJECTED || record["status"] === REPORT_STATUSES.DECLINED) && (
            <>
              <ReportListItem
                title="Reason"
                value={isUndefined(record["rejectReason"]) ? "--" : record["rejectReason"]}
              />
              <Divider light />
            </>
          )}
          {showTimeRange(record['reportType']) && (
            <React.Fragment>
              {record['timeRange'] && (
                <React.Fragment>
                  <ReportListItem
                    title="TimeRange"
                    value={
                      TIME_RANGE_MONTHS[record['timeRange']] !== undefined ?
                        TIME_RANGE_MONTHS[record['timeRange']] :
                        'CUSTOM'
                    }
                  />
                  <Divider light />
                </React.Fragment>
              )}
              <ReportListItem title="From" value={record['startDateParsed']} />
              <Divider light />
              {record['endDateParsed'] && (
                <React.Fragment>
                  <ReportListItem title="To" value={record['endDateParsed']} />
                  <Divider light />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          { showFollowUpDataRangeInput(record['reportType']) && (record['userStartDateParsed'] && (
            <React.Fragment>
              <ReportListItem title="User Start Date" value={record['userStartDateParsed']} />
              <Divider light />
            </React.Fragment>
          ))
          }
          {showAmountCurrencyAndCurrencyToProve(record['reportType']) && (
            <React.Fragment>
              <ReportListItem title="Amount Currency" value={CURRENCY_SYMBOLS[record['amountCurrency']]} />
              <Divider light />
              {isAmountToProveFieldRequired(selectedSurveys) && (
                <React.Fragment>
                  <ATPReportListItem
                    title="Amount to Prove"
                    value={record['amountToProve']}
                    disabled={isAdmin || !isReportActive}
                    isATP={true}
                  />
                  <Divider light />
                </React.Fragment>
              )}
              {record['surveyIds']?.length && (
                <React.Fragment>
                  {factFindSurveys.length > 0 && (
                    <React.Fragment>
                      <ReportListItem
                        title="Fact find questionnaire"
                        value={factFindSurveys[0].surveyDisplayName}
                        requestSurveyParams={requestSurveyParams}
                        survey={factFindSurveys[0]}
                      />
                      <Divider light />
                    </React.Fragment>
                  )}
                  {sofSurveys.length > 0 && (
                    <React.Fragment>
                      <ReportListItem
                        title="Source of funds questionnaire"
                        value={sofSurveys[0].surveyDisplayName}
                        requestSurveyParams={requestSurveyParams}
                        survey={sofSurveys[0]}
                      />
                      <Divider light />
                    </React.Fragment>
                  )}
                  { displaySurveyATPonShow(record['reportType'], sofSurveys.length) && (
                    <>
                      <ReportListItem title="Survey Amount to Prove" value={record['surveyAmountToProve']} />
                      <Divider light />
                    </>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {( showInsights(record['reportType']) || showForIncome(record['reportType'])) &&
            <>
              <ReportListItem
                title="Income Insights"
                value={
                  record['insights'] ?
                    (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
                    (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
                }
              />
              <Divider light />
              <ReportListItem
                title="Income Mandatory"
                value={
                  record['incomeMandatory'] ?
                    (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
                    (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
                }
              />
              <Divider light />
            </> }
          {showForIncome(record['reportType']) &&
            <>
              <ReportListItem
                title="Payslip Verification"
                value={
                  record['payslipVerification'] ?
                    (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
                    (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
                }
              />
              <Divider light />
            </> }
          <ReportListItem title="Email Text" value={record['emailText']} />
          <Divider light />
          {showLargeItemInput(record['reportType']) &&
            (<>
              {record['largeItemIncomeThreshold'] &&
                <>
                  <ReportListItem title="Large Item Income Threshold" value={record['largeItemIncomeThreshold']} />
                  <Divider light />
                </>
              }
              {record['largeItemExpenseThreshold'] &&
                <>
                  <ReportListItem title="Large Item Expense Threshold" value={record['largeItemExpenseThreshold']} />
                  <Divider light />
                </>
              }
            </>)
          }
          <ReportListItem
            title="Email"
            value={
              record['automatedEmailSent'] ?
                (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
                (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
            }
          />
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Reminders</Grid>
              <Grid xs={12} md={8}>
                <DisableReminder
                  clientRequestId={record['clientRequestId']}
                  scheduledReminders={record['scheduledReminders']}
                  emailAddress={record['emailAddress']}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ReportListItem title="Created" value={record['created']} />
          <Divider light />
          <ReportListItem title="Last Modified" value={record['lastModified']} />
          <Divider light />
          <ReportListItem
            title="Preview"
            value={
              isPdfEnabled(record['reportType']) ?
              <PreviewLink
                clientRequestId={record['clientRequestId']}
                clientId={record['clientId']}
                status={record['status']}
              /> : "--"
            }
          />
          <Divider light />
          <ReportListItem title="Download" value={<DownloadLink columns={false} {...record} />} />
          <Divider light />
          { !isAdmin && expandReportEnabled && (
            <>
              <ReportListItem title="Expand" value={<ExpandReportButton record={record} basePath={RESOURCES_BASEPATH.REPORTS} />} />
              <Divider light />
            </>
          )}
          <ListItem sx={padding1.y}>
            <Grid item xs={12} container justifyContent="flex-end">
              { showOpenEmailClientButton(process.env.REACT_APP_DISPLAY_OPEN_EMAIL_CLIENT_BUTTON) &&
                <Grid item xs={12} md={4} lg={3} container>
                  <OpenEmailClient
                    clientRequestId={record['clientRequestId']}
                    disabled={isAdmin || record['hidden'] || !isReportActive}
                  />
                </Grid>
              }
              <Grid item xs={12} md={4} lg={3} container>
                <SendAutomatedEmailDialog
                  clientRequestId={record['clientRequestId']}
                  disabled={isAdmin || record['hidden'] || !isReportActive}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3} container>
                <Button
                  size="medium"
                  onClick={() => copyToClipboard(record.redirectUrl)}
                  disabled={!isReportActive}
                  sx={{...baseButtonStyle()}}
                >
                  Copy Report Link
                </Button>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

const ATPReportListItem = (props) => {
  
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);    
  }
  
  const handleDialogClose = (event, reason) => {
    if(reason !== EVENT_REASON.BACKDROP_CLICK){
      setOpen(false)
    }      
  };

  return (
    <ListItem sx={padding2.y}>
      <Grid container>
        <Grid item xs={12} md={4} sx={textColor.gray}>{props.title}</Grid>
        <Grid item container xs={12} md={6} lg={5} alignItems="center">
          {props.value ?
            <span style={margin1.right}>{props.value}</span> :
            ''
          }
          {!props.disabled ?
            <Grid item>
              <FunctionField
                source="Edit"
                label={false}
                sortable={false}
                render={(record) => (
                  <CustomTooltip title="Edit" placement="top">
                    <Button
                      size="medium"
                      sx={{...iconAction, marginLeft: { xs: props.value ? "" : "-12px", md: props.value ? "" : "-20px" } }}
                      onClick={handleClick}
                    >
                      <EditOutlinedIcon/>
                    </Button>
                  </CustomTooltip>
                )}
              />
              <SimpleForm
                toolbar={null}
                component={SimpleFormContainerComponent}
              >
                <ATPDialogForEditReport open={open} handleDialogClose={handleDialogClose} isATP={props.isATP}/>
              </SimpleForm>
            </Grid>
            : null}
        </Grid>        
      </Grid>
    </ListItem>
  );
};

const SimpleFormContainerComponent = props => (
  //Since SimpleForm child components are rendered in CardContent, we need a basic custom container that renders the form's children
  <>
    {props.children}
  </>
);

const ATPDialogForEditReport = (props) => {
  
  const dataProvider = useDataProvider();
  const values = useWatch();
  const refresh = useRefresh();  
  const notify = useNotify();
  
  const onSuccess = () => {
    notify("Report Updated", { type: 'info', autoHideDuration: 1000 });
    props.handleDialogClose();
    refresh();
  };

  const onFailure = (error) => {
    notify(error.message, { type: 'info', autoHideDuration: 1000 });
    refresh();
  };

  const handleSubmit = () => {     
    dataProvider
        .atpAmendment(RESOURCES.REPORTS, {...values, id: values.id})
        .then(onSuccess)
        .catch(onFailure)    
  }

  return(    
    <CustomDialog       
      openDialog = {props.open}
      closeDialog = {props.handleDialogClose}
      handleSubmit={handleSubmit}
      title = { props.isATP ? "Update Amount to Prove" : "Update Survey Amount to Prove"}                          
      content = {
        <NumberInput
          onWheel={(e) => {
            e.target.blur();
          }} 
          source={ props.isATP ? "amountToProve" : "surveyAmountToProve"}
          label= { props.isATP ? "Amount to Prove" : "Survey Amount to Prove"}
          variant="outlined"
          size="small"
          onKeyPress={(e) => {              
            if (e.key === "e") {
              e.preventDefault();
            }
          }}
          min={0}
          validate={positiveAmount}                        
        />                 
      }
      invalid={
        props.isATP ? ( values?.amountToProve === null || values?.amountToProve < 0 )
        : ( values?.surveyAmountToProve === null || values?.surveyAmountToProve < 0 )
      }
    />
  )
}

