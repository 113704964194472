import React from 'react';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import CustomDatePicker from "../common/DatePicker";
import momentDate from "../../utils/moment-date";
import { useFormContext } from 'react-hook-form';
import {
  ReportTypeChoices,
  DEFAULT_TIME_RANGES_FOR_REPORT_TYPE,
  REPORT_TYPES,
  FREQUENCY_OPTIONS,
  FREQUENCY,
  TIME_RANGES,
  TIME_RANGES_STRING_VALUES,
} from '../Constants';
import {
  createReportChoicesDynamically,
  createFrequencyChoicesDynamically,
  requiredValidation,
  createTimeRangeChoicesDynamically,
  getChoicesForTimeRange
} from '../../utils/util';
import {
  validateReportRequestCreation,
  areDependentValidationsSatisfied,
  getPastDateFromFrequency,
  showTimeRange,
  getPossibleChoicesAndDefaultValue,
  canFieldBeHidden
} from '../../utils/CreateReportUtils';
import moment from "moment-timezone";
import {
  SelectInput,
  RadioButtonGroupInput,
  CheckboxGroupInput,
} from "react-admin";
import { isSchedulingSupported, isReportSchedulingEnabled } from "../DataProvider";
import { useWatch } from 'react-hook-form';
import {datePickerError,padding2,textColor,width} from '../Styles'

let reportTypeFilterString = "";
export const ReportTypeAndDynamicTimeRange = (props) => {
  const form = useFormContext();
  const values = useWatch();
  const reportField = props.reportField;
  const frequencyField = props.frequencyField;
  const timeRangeField = props.timeRangeField;
  const allowedReports =
    reportField && reportField.possibleValues
      ? createReportChoicesDynamically(reportField.possibleValues)
      : ReportTypeChoices;

  /*if (!values.timeRange) {
    form.setValue('timeRange', null);
  } TODO: Causing react exhaustive deps error need to solve later*/

  // setting report type initially for defaulting
  const initialReportType = allowedReports.length === 1 ? allowedReports[0].id : null;

  const [reportType, setReportType] = React.useState(initialReportType);
  React.useEffect(() => {
    function setDefaultReportType() {
    setReportType(initialReportType);
      form.setValue('reportType', initialReportType);
    }
    setDefaultReportType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialReportType]);

  // setting timeRange default value for defaulting
  const choicesAndDefaultValue = getPossibleChoicesAndDefaultValue(reportType, timeRangeField)
  const initialDefaultRange = choicesAndDefaultValue && allowedReports.length === 1 ? TIME_RANGES[choicesAndDefaultValue.defaultValue] : DEFAULT_TIME_RANGES_FOR_REPORT_TYPE[reportType]
  
  const [defaultRange, setDefaultRange] = React.useState(initialDefaultRange)
  React.useEffect(() => {
    function setDefaultTimeRange() {
      setDefaultRange(initialDefaultRange);
      form.setValue("timeRange", initialDefaultRange)
      if(initialReportType)
        form.setValue("reportType", initialReportType)
    }
    setDefaultTimeRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDefaultRange,initialReportType, props.selectedTemplateId]); // when template is changed, reportType could be same deafulted same but time range could be different
  
  // filtering report type
  const filterReportType = (e) => {
    const searchTerm = e.key.toLowerCase();
    let newReportType = null;
    if (reportTypeFilterString && searchTerm === reportTypeFilterString[0]) {
      reportTypeFilterString = `${reportTypeFilterString}${searchTerm}`;
      const filteredReportTypes = allowedReports.filter((report) => report.name.toLowerCase().startsWith(searchTerm));
      if (filteredReportTypes && filteredReportTypes.length) {
        const index =
          filteredReportTypes.length >= reportTypeFilterString.length
            ? reportTypeFilterString.length
            : filteredReportTypes.length;
        newReportType = filteredReportTypes[index - 1].id;
      }
    } else {
      const filteredReportType = allowedReports.find((report) => report.name.toLowerCase().startsWith(searchTerm));
      if (filteredReportType) {
        newReportType = filteredReportType.id;
      }
      reportTypeFilterString = searchTerm;
    }
    if (newReportType) {
      form.setValue('reportType', newReportType);
      setReportType(newReportType);
    }
  };

  // creating time range choices
  const timeRangeChoices = choicesAndDefaultValue
    ? createTimeRangeChoicesDynamically(choicesAndDefaultValue.values)
    : getChoicesForTimeRange(reportType);

  // changing report type and setting default timeRange
  const changeReportTypeAndRange = (event) => {
    setReportType(event.target.value);
  };

  React.useEffect(() => {
    function setDefaultTimeRange() {
      const choices = getPossibleChoicesAndDefaultValue(reportType, timeRangeField);
      setDefaultRange(
        choices ? TIME_RANGES_STRING_VALUES[choices.defaultValue] : DEFAULT_TIME_RANGES_FOR_REPORT_TYPE[reportType]
      );
      form.setValue(
        'timeRange',
        choices ? TIME_RANGES_STRING_VALUES[choices.defaultValue] : DEFAULT_TIME_RANGES_FOR_REPORT_TYPE[reportType]
      );
    }
    if (reportType && DEFAULT_TIME_RANGES_FOR_REPORT_TYPE[reportType]) {
      setDefaultTimeRange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType]);

  // changing time range
  const changeTimeRange = (event) => {
    setDefaultRange(event.target.value);
    form.setValue('timeRange', event.target.value);
  };

  return (
    <React.Fragment>
      <Grid sx={{
        ...(reportField && canFieldBeHidden(
          reportField.isHidden,
          reportField.required,
          reportField.defaultValue
        ) ?{ display: "none" } : {})
      }}>
        <ListItem sx={padding2.y}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} sx={textColor.gray}>
              {reportField ? reportField.displayName : 'Report Type'}
              {reportField && reportField.required && <span style={{...textColor.red, marginLeft: '3px'}}>*</span>}
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <SelectInput
                source="reportType"
                name="reportType"
                variant="outlined"
                size="small"
                label={reportField ? reportField.placeholder : 'Choose an option'}
                choices={allowedReports}
                onChange={changeReportTypeAndRange}
                defaultValue={allowedReports.length === 1 ? allowedReports[0].id : undefined}
                validate={reportField.required ? requiredValidation : null}
                onKeyPress={filterReportType}
                sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider light />
      </Grid>
      <FrequencyOptions frequencyField={frequencyField} />
      {showTimeRange(reportType) && !isSchedulingSupported(reportType, values.frequency) && (
        <React.Fragment>
          <Grid sx={{
            ...(timeRangeField && canFieldBeHidden(
              timeRangeField.isHidden,
              timeRangeField.required,
              defaultRange
            ) ? { display: "none" } : {})
          }}>
            <ListItem sx={padding2.y}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={4} sx={textColor.gray}>
                  {timeRangeField ? timeRangeField.displayName : 'Time Range'}
                  {timeRangeField && timeRangeField.required && (
                    <span style={{...textColor.red, marginLeft: '3px'}}>*</span>
                  )}
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <SelectInput
                    source="timeRange"
                    name="timeRange"
                    variant="outlined"
                    size="small"
                    label={timeRangeField ? timeRangeField.placeholder : 'Choose an option'}
                    choices={timeRangeChoices}
                    onChange={changeTimeRange}
                    defaultValue={defaultRange}
                    isRequired
                    sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
                    />
                </Grid>
              </Grid>
            </ListItem>
            <Divider light />
          </Grid>
          <DynamicDateRange />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const FrequencyOptions = (props) => {
  const frequencyField = props.frequencyField;
  const values = useWatch();
  
  if (
    !values.reportType ||
    isReportSchedulingEnabled() === false ||
    ((!frequencyField || !frequencyField.dependentOn) && values.reportType !== REPORT_TYPES.TRANSACTION_DOWNLOAD) ||
    (frequencyField && frequencyField.dependentOn && !areDependentValidationsSatisfied(frequencyField.dependentOn, values))
  )
    return null;

  return (
    <React.Fragment>
      <Grid sx={{
        ...(frequencyField && canFieldBeHidden(
          frequencyField.isHidden,
          frequencyField.required,
          frequencyField.defaultValue)
          ? { display: "none" } : {})
      }}>
        <ListItem sx={padding2.y}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} sx={textColor.gray}>
              {frequencyField ? frequencyField.displayName : 'Frequency'}
              {frequencyField && frequencyField.required &&
                <span style={{...textColor.red, marginLeft: '3px'}}>*</span>}
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <RadioButtonGroupInput
                label={false}
                name="frequency"
                source="frequency"
                required={frequencyField && frequencyField.required}
                row={true}
                defaultValue={frequencyField ? frequencyField.defaultValue : 'ONCE'}
                choices={
                  frequencyField && frequencyField?.possibleValues ? createFrequencyChoicesDynamically(frequencyField.possibleValues) : FREQUENCY_OPTIONS
                }
                sx={{marginBottom: "-8px" }}
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider light />
      </Grid>
      <ScheduleEndDateComponent />
    </React.Fragment>
  );
};


const ScheduleEndDateComponent = () => {
  const values = useWatch();
  const reportScheduleMinDate = getPastDateFromFrequency(
    new Date(),
    values.frequency
  );

  if (values.frequency && values.frequency !== FREQUENCY.ONCE) {    
    return (
      <React.Fragment>
        <ListItem sx={padding2.y}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} sx={textColor.gray}>
              End Date Of First Report
              <span style={{...textColor.red, marginLeft: '3px'}}>*</span>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
              <CustomDatePicker
                isStartDate={false}
                isEndDateOfFirstReport={true}
                reportScheduleMinDate={reportScheduleMinDate}
                sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
              />
              <CheckboxGroupInput 
                source='scheduleReminders'
                choices={[
                  { id: 'scheduleReminders', name: 'Send Reminder Emails'},
                ]}
                defaultValue={['scheduleReminders']}
                label={false}
                helperText={false}
                sx={{ marginBottom: "0" }}
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider light />
        <ListItem sx={padding2.y}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} sx={textColor.gray}>Dates Of First Report</Grid>
            <Grid item xs={12} md={6} lg={5}>
              <strong>
                {values.firstPeriodEndDate
                  ? momentDate(
                      getPastDateFromFrequency(
                        values.firstPeriodEndDate,
                        values.frequency
                      )
                    ).format("YYYY-MM-DD")
                  : momentDate(
                      getPastDateFromFrequency(new Date(), values.frequency)
                    ).format("YYYY-MM-DD")}
                {" to "}
                {values.firstPeriodEndDate
                  ? momentDate(values.firstPeriodEndDate).format("YYYY-MM-DD")
                  : moment().format("YYYY-MM-DD")}
              </strong>
            </Grid>
          </Grid>
        </ListItem>
        <Divider light />
      </React.Fragment>
    );
  }

  return null;
};

const DynamicDateRange = () => {
  const values = useWatch();
  return (
    showTimeRange(values.reportType) &&
    !isSchedulingSupported(values.reportType, values.frequency) &&
    values.timeRange !== undefined &&
    values.timeRange === "CUSTOM" && (
      <React.Fragment>
        <ListItem sx={padding2.y}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} sx={textColor.gray}>
              From
              <span style={{...textColor.red, marginLeft: '3px'}}>*</span>
            </Grid>
            <Grid item xs={12} md={5} lg={4} sx={{marginBottom: "-15px"}} className={{'invalid-date-range': validateReportRequestCreation(values).startDate != null}}>
              <CustomDatePicker isStartDate={true} />
              <p style={datePickerError}>
                {validateReportRequestCreation(values).startDate != null ? validateReportRequestCreation(values).startDate : (<div>&nbsp;</div>) }
              </p>
            </Grid>
          </Grid>
        </ListItem>
        <Divider light />
        <ListItem sx={padding2.y}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} sx={textColor.gray}>
              To
              <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>
            </Grid>
            <Grid item xs={12} md={5} lg={4} sx={{marginBottom: "-15px"}} className={{'invalid-date-range': validateReportRequestCreation(values).endDate != null}}>
              <CustomDatePicker isStartDate={false} isEndDate={true} />
              <p style={datePickerError}>
                {validateReportRequestCreation(values).endDate != null ? validateReportRequestCreation(values).endDate : (<div>&nbsp;</div>) }
              </p>
            </Grid>
          </Grid>
        </ListItem>
        <Divider light />
      </React.Fragment>
    )
  );
};

export default ReportTypeAndDynamicTimeRange;