import { REPORT_TYPES, TIME_RANGES, FREQUENCY, FOLLOW_UP_DATE_RANGE_CHOICES, FOLLOW_UP_DATE_RANGE, FOLLOW_UP_DATE_RANGE_DISPLAY_NAMES } from '../components/Constants'
import { isExtendedDataRangeEnabled } from '../components/DataProvider';
import momentDate from "./moment-date"
import { findDiffInMonths, getPastDateByMonths, shouldDateBeStartOfMonth } from "./util"
import isEmpty from 'lodash/isEmpty'

// for cases when fact find survey is present, ATP always goes in through survey params
export const isAmountToProveFieldRequired = (values) => {
  if ((!values.selectedSourceOfFundsSurvey && !values.selectedFactFindSurvey) || isAmountToProveMandatory(values))
    return true;
  else
    return false;
};

export const isAmountToProveMandatory = (values) => {
  return (
    (values.selectedSourceOfFundsSurvey && !values.selectedFactFindSurvey) ||
    (values.selectedFactFindSurvey &&
      values.selectedSourceOfFundsSurvey &&
      (!values.selectedFactFindSurvey.outputParams ||
        (values.selectedFactFindSurvey.outputParams &&
          !values.selectedFactFindSurvey.outputParams.includes('AMOUNT_TO_PROVE'))))
  );
};

export const getPastDateFromFrequency = (date, frequency) => {
  if (!frequency || FREQUENCY.ONCE === frequency) return;

  let periodStartDate = null;
  if (FREQUENCY.MONTHLY === frequency)
    periodStartDate = momentDate(date).subtract(1, "months").toDate();
  else if (FREQUENCY.QUARTERLY === frequency)
    periodStartDate = momentDate(date).subtract(3, "months").toDate();
  else periodStartDate = momentDate(date).subtract(1, "years").toDate();

  if (isEndOfMonth(date)) {
    periodStartDate = momentDate(periodStartDate).add(1, "months").startOf("month");
  } else {
    periodStartDate = momentDate(periodStartDate).add(1, "days").toDate();
  }

  return periodStartDate;
};

export function isEndOfMonth(dt) {
  if (typeof dt === "string") dt = new Date(dt);

  var date = new Date(dt.getTime()),
    month = date.getMonth();

  date.setDate(date.getDate() + 1);
  return date.getMonth() !== month;
}

export const validateStartDate = (value, allValues) => {
  const extendedDataRangeEnabled = isExtendedDataRangeEnabled();
  const { endDate, reportType, timeRange } = allValues;

  if (value && endDate && timeRange === TIME_RANGES.CUSTOM) {
    if (
      momentDate(value).isBefore(getPastDateByMonths(new Date(), 24), "days") &&
      !extendedDataRangeEnabled
    ) {
      return "The start date cannot be more than 24 months before current date.";
    }

    if (
      shouldDateBeStartOfMonth(reportType) &&
      findDiffInMonths(value, endDate) < 3
    ) {
      return "The time range must be at least 3 months.";
    }
  }
};

export const validateEndDate = (value, allValues) => {
  const { startDate, timeRange } = allValues;

  if (startDate && value && timeRange === TIME_RANGES.CUSTOM) {
    if (momentDate(value).isBefore(startDate)) {
      return "The start date must be a date before or equal to end date.";
    }
  }
};

export const validateReportRequestCreation = (values, fields) => {

  const extendedDataRangeEnabled = isExtendedDataRangeEnabled();
  const { startDate, endDate, reportType } = values;
  const errors = {};

  if (startDate && endDate && TIME_RANGES.CUSTOM === values.timeRange) {
    if (momentDate(startDate).isBefore(getPastDateByMonths(new Date(), 24), "days") && !extendedDataRangeEnabled) {
      errors.startDate =
      "The start date cannot be more than 24 months before current date.";
    }
    if (momentDate(endDate).isBefore(startDate)) {
      errors.endDate =
        "The start date must be a date before or equal to end date.";
    }
     
    if (
      shouldDateBeStartOfMonth(reportType) &&
      findDiffInMonths(startDate, endDate) < 3
    ) {
      errors.startDate = "The time range must be at least 3 months.";
    }
  }
  return errors;
};

export const showAmountCurrencyAndCurrencyToProve = (reportType) => {
  return (
    reportType === REPORT_TYPES.PROOF_OF_FUNDS ||
    reportType === REPORT_TYPES.SOURCE_OF_FUNDS_DATA ||
    reportType === REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS ||
    reportType === REPORT_TYPES.SURVEY_ONLY
  );
};

export const showTimeRange = (reportType) => {
  return (
    reportType === REPORT_TYPES.SOURCE_OF_FUNDS_DATA ||
    reportType === REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS ||
	  reportType === REPORT_TYPES.TRANSACTION_ANALYSIS ||
	  reportType === REPORT_TYPES.CLAIMS_REVIEW ||
	  reportType === REPORT_TYPES.TRANSACTION_DOWNLOAD ||
    reportType === REPORT_TYPES.INCOME ||
    reportType === REPORT_TYPES.AFFORDABILITY ||
	  reportType === REPORT_TYPES.DETAILED_AFFORDABILITY 
  );
};

export const showFollowUpDataRangeInput = (reportType) => {
  return ( reportType === REPORT_TYPES.INCOME ||
    reportType === REPORT_TYPES.AFFORDABILITY ||
    reportType === REPORT_TYPES.DETAILED_AFFORDABILITY ||
    reportType === REPORT_TYPES.CLAIMS_REVIEW
  );
}

export const showPayslipVerificationInput = (reportType) => {
  return (
    reportType === REPORT_TYPES.INCOME ||
    reportType === REPORT_TYPES.AFFORDABILITY ||
	  reportType === REPORT_TYPES.DETAILED_AFFORDABILITY 
  );
};

export const filterFieldBasedOnName = (fieldName, fields) => {
  const filteredList = fields.filter(field => field.name === fieldName)
  return filteredList.length > 0 ? filteredList[0] : null
}

export const getPossibleChoicesAndDefaultValue = (reportType, timeRangeField) => {
  // getting possible choices and default values based on reportType
  if (reportType && timeRangeField && timeRangeField.possibleValues.length > 0) {
    const possibleChoicesAndDefaultValues = timeRangeField.possibleValues.filter(
      (item) => item.reportType === reportType
    );
    if (possibleChoicesAndDefaultValues) return(possibleChoicesAndDefaultValues[0]);
    else return null;
  }
  else return null;
}

export const areDependentValidationsSatisfied = (dependsOn, formStateValues) => {
  if (!dependsOn) return true;

  let result = true;
  dependsOn.forEach((dependent) => {
    switch (dependent.operator) {
      case 'EQUALS':
        if (formStateValues[dependent.field] === dependent.values[0]) result = result && true;
        else result = result && false;
        break;

      case 'WITHIN_RANGE':
        if (dependent.values.includes(formStateValues[dependent.field])) result = result && true;
        else result = result && false;
        break;

      case 'LESS_THAN':
        if (formStateValues[dependent.field] < dependent.values[0]) result = result && true;
        else result = result && false;
        break;

      case 'GREATER_THAN':
        if (formStateValues[dependent.field] > dependent.values[0]) result = result && true;
        else result = result && false;
        break;

      default:
        break;
    }
  });
  return result;
};

export const canFieldBeHidden = (hidden, required, defaultValue) => {
  if (hidden) return !required || (required && defaultValue);
  else return false;
};

export const showSurveyATP = (values) => {
  return ( values.reportType === REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS 
    || values.reportType === REPORT_TYPES.SOURCE_OF_FUNDS_DATA ) 
    && !isEmpty(values.selectedSourceOfFundsSurvey);
}

export const displaySurveyATPonShow = (reportType, surveySize) => {
  return (( reportType === REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS 
        || reportType === REPORT_TYPES.SOURCE_OF_FUNDS_DATA 
        || reportType === REPORT_TYPES.TRANSACTION_ANALYSIS ) 
        && surveySize > 0 );
}

export const showInsights = (reportType) => {
  return ( reportType === REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS )
}

export const showLargeItemInput = (reportType) => {
  return ( reportType === REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS )
}

export const showForExpansion = (reportType) => {
  return ( reportType === REPORT_TYPES.INCOME ||
    reportType === REPORT_TYPES.AFFORDABILITY ||
    reportType === REPORT_TYPES.DETAILED_AFFORDABILITY ||
    reportType === REPORT_TYPES.CLAIMS_REVIEW ||
    reportType === REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS ||
    reportType === REPORT_TYPES.TRANSACTION_ANALYSIS
  );
}

export const showForIncome = (reportType) => {
  return ( reportType === REPORT_TYPES.INCOME ||
    reportType === REPORT_TYPES.AFFORDABILITY ||
    reportType === REPORT_TYPES.DETAILED_AFFORDABILITY);
}

export const createFollowUpDataRangeChoices = (possibleValues) => {  
  
  if (isEmpty(possibleValues)) return FOLLOW_UP_DATE_RANGE_CHOICES

  const options = [];
  possibleValues.forEach((value) => {
    if (FOLLOW_UP_DATE_RANGE.hasOwnProperty(value)){
      options.push({
        id : FOLLOW_UP_DATE_RANGE[value],
        name : FOLLOW_UP_DATE_RANGE_DISPLAY_NAMES[value]
      })
    }
  })

  if (isEmpty(options)) return FOLLOW_UP_DATE_RANGE_CHOICES

  return options;
}

export const getDefaultValueForFollowUpDataRange = (followUpDataRangeField, followUpDataRangeChoices) => {
  
  let followUpDataRangeIds = followUpDataRangeChoices.map(choice => choice.id);

  if(followUpDataRangeIds.includes(followUpDataRangeField?.defaultValue)){
    return followUpDataRangeField?.defaultValue;
  }else{
    return undefined;
  }

}