import { v4 as uuidv4 } from 'uuid';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import {
  parseEndDate,
  getParsedDate,
  calculateStartDate,
  getDateComponent,
  getMonthsBetween,
  isTransactionalReport,
  calculateUserStartDate,
  isIncomeReport,
  parseDateForExport,
  calculateStartDateUsingMidDate,
  calculateEndDateUsingMidDate,
  formatEndDate,
  formatStartDate,
} from '../utils/util';
import { isAdminUser, isSchedulingSupported } from '../components/DataProvider';
import { DISPLAY_OPEN_EMAIL_CLIENT_BUTTON, FOLLOW_UP_DATE_RANGE_MONTHS, INSIGHTS_TYPE, REPORT_STATUSES, SURVEY_TYPES, TIME_RANGE_INPUT } from '../components/Constants';
import isEmpty from 'lodash/isEmpty';
import isUndefined from "lodash/isUndefined";
import { showForExpansion } from '../utils/CreateReportUtils';

export const parseReportsData = (data) => {
  //Note:- If you're adding a new field to the report list screen, make sure it's in the exported CSV, so make sure you modify parseReportForExport.
  const {
    clientRequestId,
    clientId,
    requestId,
    requestParams,
    reportType,
    status,
    scheduledReminders,
    automatedEmailSent,
    surveyParams,
    hidden,
    requestingUserId,
    reportRequestScheduleId,
    onBehalfOfId,
  } = data;
  const { userData, reportParams, clientReference, insightsRequests } = JSON.parse(requestParams); // Had to parse requestParams to JSON explictily becase API sends it as simple string instead of JSON>
  const lastModified = getParsedDate(data.lastModified);
  const created = getParsedDate(data.created); 
  const insights = ( !isEmpty(insightsRequests) &&  insightsRequests[0].insightsType === INSIGHTS_TYPE.INCOME );
  const report = {
    id: isAdminUser() ? 'client/' + clientId + '/detail/' + clientRequestId : clientRequestId,
    clientId,
    clientRequestId,
    requestId,
    contactName: userData.accountName,
    emailAddress: userData.emailAddress,
    businessName: reportParams ? reportParams.businessName : '',
    descriptionOfMatter: reportParams ? reportParams.descriptionOfMatter : '',
    startDate: reportParams ? reportParams.startDate : '',
    userStartDate: reportParams ? reportParams.userStartDate : '',
    endDate: reportParams ? reportParams.endDate : '',
    originalClientRequestId: reportParams ? reportParams.originalClientRequestId : '',
    surveyParams,
    clientReference,
    reportType,
    status,
    automatedEmailSent,
    scheduledReminders,
    requestingUserId,
    reportRequestScheduleId,
    hidden,
    created,
    lastModified,
    insights,
    onBehalfOfId,
  };

  return report;
};

export const parseCreateReportRequestBodyData = (data) => {
  const {
    contactName,
    emailAddress,
    businessName,
    descriptionOfMatter,
    clientReference,
    reportType,
    timeRange,
    startDate,
    endDate,
    amountCurrency,
    amountToProve,
    surveyAmountToProve,
    emailText,
    frequency,
    firstPeriodEndDate,
    scheduleReminders,
    insights,
    incomeMandatory,
    payslipVerification,
    followUpDataRange,
    reportLength,
    midDate,
    dateRange,
    reportStartDate,
    reportEndDate,
    originalClientRequestId,
    timeRangeOptions
  } = data;
  const requestBody = {
    version: '2',
    clientRequestId: uuidv4(),
    clientReference,
    reportType,
    userData: {
      accountName: startCase(toLower(contactName)), //capitalizae initial alphabets in the user name
      emailAddress: emailAddress ? emailAddress.toLowerCase().trim() : emailAddress,
    },
  };

  const groupingParams = prepareGroupingParams(data);

  requestBody.reportParams = {
    amountCurrency,
    amountToProve,
    surveyAmountToProve,
    businessName,
    descriptionOfMatter,
    emailText,
    originalClientRequestId,
    groupingParams,
  };

  const selectedSurveys = [];
  if(!isEmpty(data.selectedFactFindSurvey)){
    selectedSurveys.push(data.selectedFactFindSurvey);
  }
  if(!isEmpty(data.selectedSourceOfFundsSurvey)){
    selectedSurveys.push(data.selectedSourceOfFundsSurvey);
  }

  if (!isEmpty(selectedSurveys) && selectedSurveys.length) {
    requestBody.surveyParams = selectedSurveys;
    setSurveyParameters(data, selectedSurveys) // setting input parameters to the specific surveys    
  }

  if (frequency && isSchedulingSupported(reportType, frequency)) {
    requestBody.scheduleRequest = {
      frequency,
      firstPeriodEndDate,
      scheduleReminders : scheduleReminders.includes('scheduleReminders'),
    };
  } else if (isTransactionalReport(reportType) && (!timeRangeOptions)) {
    // if not a schedule request & is a date based report only then send start date and end date along with report params
    requestBody.reportParams.startDate = calculateStartDate(timeRange, startDate, reportType);
    requestBody.reportParams.endDate = parseEndDate(timeRange, endDate);
    requestBody.reportParams.userStartDate = calculateUserStartDate(followUpDataRange);
  }

  if(timeRangeOptions === TIME_RANGE_INPUT.REPORT_LENGTH) {
    requestBody.reportParams.startDate = calculateStartDate(FOLLOW_UP_DATE_RANGE_MONTHS[reportLength], startDate, reportType);
  }
  else if(timeRangeOptions === TIME_RANGE_INPUT.DATE_RANGE) {
    requestBody.reportParams.startDate = formatStartDate(startDate);
    requestBody.reportParams.endDate = formatEndDate(endDate);
  }
  else if(timeRangeOptions === TIME_RANGE_INPUT.MID_DATE_RANGE) {
    requestBody.reportParams.startDate = formatStartDate(calculateStartDateUsingMidDate(dateRange, midDate, reportStartDate));
    requestBody.reportParams.endDate = formatEndDate(calculateEndDateUsingMidDate(dateRange, midDate, reportEndDate));
  }

  if(insights){
    requestBody.insightsRequests = [{ 
      insightsType : INSIGHTS_TYPE.INCOME,
      incomeMandatory : incomeMandatory,
    }];
  }

  if (isIncomeReport(reportType)) {    
    requestBody.insightsRequests = [{ 
      insightsType : INSIGHTS_TYPE.INCOME,      
      payslipVerification : payslipVerification, 
    }];
  }

  return JSON.stringify(requestBody);
};

export const parseOneReportRequestData = (data) => {
  const {
    requestId,
    clientId,
    clientRequestId,
    reportParams,
    reportType,
    status,
    automatedEmailSent,
    scheduledReminders,
    hidden,
    redirectUrl,
    surveyParams,
    rejectReason,
  } = data;

  const jsonReportParams = JSON.parse(reportParams);
  const { accountName, emailAddress } = jsonReportParams.userData || {};

  const { businessName, descriptionOfMatter, startDate, endDate, userStartDate, amountCurrency, amountToProve, surveyAmountToProve, emailText, originalClientRequestId, groupingParams  } =
    jsonReportParams.reportParams || {};

  const { clientReference } = jsonReportParams;
  const created = getParsedDate(data.created);
  const lastModified = getParsedDate(data.lastModified);
  const startDateParsed = getDateComponent(startDate);
  const userStartDateParsed = getDateComponent(userStartDate);
  const endDateParsed = getDateComponent(endDate);
  const timeRange = getMonthsBetween(startDateParsed, endDateParsed);
  const surveyIds = surveyParams?.map((survey) => survey.surveyId);
  const insights = ( !isEmpty(jsonReportParams?.insightsRequests) &&  (jsonReportParams.insightsRequests[0]).insightsType === INSIGHTS_TYPE.INCOME );  
  const incomeMandatory = ( !isEmpty(jsonReportParams?.insightsRequests) &&  (jsonReportParams.insightsRequests[0]).incomeMandatory);  
  const payslipVerification = ( !isEmpty(jsonReportParams?.insightsRequests) &&  (jsonReportParams.insightsRequests[0]).payslipVerification);  
  const { largeItemIncomeThreshold, largeItemExpenseThreshold } = getGroupingParams(groupingParams);

  const reportRequest = {
    id: isAdminUser() ? 'client/' + clientId + '/detail/' + clientRequestId : clientRequestId,
    requestId,
    clientId,
    clientRequestId,
    contactName: accountName,
    emailAddress,
    businessName,
    descriptionOfMatter,
    clientReference,
    reportType,
    timeRange,
    startDateParsed,
    userStartDateParsed,
    endDateParsed,
    amountCurrency,
    amountToProve,
    surveyAmountToProve,
    emailText,
    status,
    surveyParams,
    surveyIds,
    automatedEmailSent,
    scheduledReminders,
    hidden,
    created,
    lastModified,
    redirectUrl,
    insights,
    incomeMandatory,
    rejectReason,
    payslipVerification,
    originalClientRequestId,
    largeItemIncomeThreshold,
    largeItemExpenseThreshold,
  };
  return reportRequest;
};

export const parsePatchReportRequesBodyData = (data) => {
  const keys = Object.keys(data);
  data.reportParams = {
    amountToProve: data['amountToProve'],
    surveyAmountToProve: data['surveyAmountToProve'],
  };
  keys.forEach((key) => delete data[key]);
  return JSON.stringify(data);
};

export const parsePatchSurveyParams = (data) => {
  const body = {}
  body.reportParams = null
  body.surveyParams = data
  return JSON.stringify(body)
}

const setSurveyParameters = (data, selectedSurveys) => {   
  let factFindSurveys = selectedSurveys.filter((survey) => survey.surveyType === SURVEY_TYPES.FACT_FIND);
  let sourceOfFundsSurveys = selectedSurveys.filter((survey) => survey.surveyType === SURVEY_TYPES.SOURCE_OF_FUNDS);
  assignFieldValuesToSurvey(data) 
  const factFindFields = Object.keys(data).filter((key) => key.includes('factfind-field'));
  const sofFields = Object.keys(data).filter((key) => key.includes('sof-field'));

  if (factFindSurveys.length) {
    let factFindSurvey = factFindSurveys[0];
    factFindSurvey.inputParams = {};
    factFindFields.forEach((field) => {
      // for default keys, directly pick keys and map values(display name is different from key name)
      if (field.includes('default')) {
        factFindSurvey.inputParams[field.substring(field.lastIndexOf('-') + 1)] =
          data[`default-factfind-value-${field.substring(field.lastIndexOf('-') + 1)}`];
      } // for user enetered pairs, the entered value becomes the key
      else if (data[field]) {
        factFindSurvey.inputParams[data[field]] = data[`factfind-value-${field.substring(field.lastIndexOf('-') + 1)}`];
      }
    });
    delete factFindSurvey['outputParams'];
  }

  if (sourceOfFundsSurveys.length) {
    let sourceOfFundsSurvey = sourceOfFundsSurveys[0];
    sourceOfFundsSurvey.inputParams = {};
    sofFields.forEach((field) => {
      // for default keys, directly pick keys and map values(display name is different from key name)
      if (field.includes('default')) {
        sourceOfFundsSurvey.inputParams[field.substring(field.lastIndexOf('-') + 1)] =
          data[`default-sof-value-${field.substring(field.lastIndexOf('-') + 1)}`];
      }// for user enetered pairs, the entered value becomes the key
      else if (data[field]) {
        sourceOfFundsSurvey.inputParams[data[field]] = data[`sof-value-${field.substring(field.lastIndexOf('-') + 1)}`];
      }
    });
    delete sourceOfFundsSurvey['outputParams'];
  }
};

const assignFieldValuesToSurvey = (data) => {
  
  if( !isEmpty(data.selectedFactFindSurvey) && !isEmpty(data.selectedFactFindSurvey.inputParams) ){
    data.selectedFactFindSurvey.inputParams.forEach((param) => {
      data[`default-factfind-field-${param.name}`] = ""
    })
  }

  if( !isEmpty(data.selectedSourceOfFundsSurvey) && !isEmpty(data.selectedSourceOfFundsSurvey.inputParams) ){
    data.selectedSourceOfFundsSurvey.inputParams.forEach((param) => {
      data[`default-sof-field-${param.name}`] = ""
    })
  }
}

export const parseEncodedURLforClientId = (url) => {
  if(!isEmpty(url)){
    url = url.substring(url.indexOf('?') + 1);
    const dencodedUrl = decodeURIComponent(url);
    const params = new URLSearchParams(dencodedUrl);
    const filters = JSON.parse(params.get('filter'));
    return filters.clientId;
  }
}

export const parseRecordsforExport = (records, data) => {  
  return records.map((record) => {     
      return parseReportForExport(record, data);
  })
}

const parseReportForExport = ( record, reqUserName ) => {

  const {
    clientId,
    clientRequestId,
    requestId,
    contactName,
    emailAddress,
    businessName,
    descriptionOfMatter,
    startDate,
    endDate,
    surveyParams,
    clientReference,
    reportType,
    status,
    automatedEmailSent,
    scheduledReminders,	
    requestingUserId,
    reportRequestScheduleId,
    hidden,
    created,
    lastModified,
    insights,
    onBehalfOfId,
  } = record;

  const data = {  //Columns are ordered based on data object fields.
    id: isAdminUser() ? 'client/' + clientId + '/detail/' + clientRequestId : clientRequestId,
    clientId,
    clientRequestId,
    requestId,
    contactName,
    emailAddress,
    businessName,
    descriptionOfMatter,
    startDate,
    endDate,    
    clientReference,
    reportType,
    status,
    automatedEmailSent,
    scheduledReminders,	
    requestingUserName : reqUserName && reqUserName.get(requestingUserId) != null ? reqUserName.get(requestingUserId) : ' ',
    reportRequestScheduleId,
    hidden,
    created : parseDateForExport(created),
    lastModified : parseDateForExport(lastModified),
    insights,
    onBehalfOfId,
    surveyParams,
  };

  return data;
}

export function parseRequestingUserNameChoicesForClients(data) {  
  const reqUserNameList = [];
  
  reqUserNameList.push({ 
    id : localStorage.getItem('loggedInUserId'), 
    name : "My reports"
  });

  for (const [key, value] of data.entries()) {       
      reqUserNameList.push({ 
        id : key,
        name: value 
      });                   
  }

  return reqUserNameList;
}

export function parseRequestingUserNameChoicesForAdmins(data) {  
  const reqUserNameList = [];

  if(!isUndefined(data)){
    for (const [key, value] of data.entries()) {       
      reqUserNameList.push({ 
        id : key,
        name: value 
      });                   
    }
  }

  return reqUserNameList;
}

export function showOpenEmailClientButton(value) {
  if(!isUndefined(value) && value.length === DISPLAY_OPEN_EMAIL_CLIENT_BUTTON.length){
    for (let i = 0; i < value.length; i++) {
      if (value[i] !== DISPLAY_OPEN_EMAIL_CLIENT_BUTTON[i]) {
          return false;
      }
    }
  }else{
    return false;
  }
  return true;
}

export function extractQueryParamsFromURL(search, param) {
  if(!isEmpty(search)) {
    const queryString = search.slice(1);
    const params = new URLSearchParams(queryString);
    return params.get(param);
  }
}

export function extractOriginalRequestIdFromURL(path) {
  const routes = path.split("/");
  return routes[routes.length - 1];
}

export function allowCreationOfFollowUpReport(record) {
  if (isEmpty(record)) {
    return false;
  }
  return (record.status === REPORT_STATUSES.AVAILABLE && showForExpansion(record.reportType) && isEmpty(record.originalClientRequestId));
}

function prepareGroupingParams(data) {
  const groupingParams = [];

  if (data?.largeItem) {
    if (data?.largeItemIncome) {
      groupingParams.push({
        groupType: "LARGE_ITEM_INCOME",
        transactionThreshold: data?.largeItemIncomeThreshold
      })
    }

    if (data?.largeItemExpense) {
      groupingParams.push({
        groupType: "LARGE_ITEM_EXPENSE",
        transactionThreshold: data?.largeItemExpenseThreshold
      })
    }    
  } else {
    return null;
  }  

  return groupingParams;
}

function getGroupingParams(groupingParams) {
  const params = {};
  
  if(isEmpty(groupingParams)){
    return params;
  }

  let largeItemIncome = groupingParams.filter(param => param.groupType === "LARGE_ITEM_INCOME");
  let largeItemExpense = groupingParams.filter(param => param.groupType === "LARGE_ITEM_EXPENSE");;

  if(!isEmpty(largeItemIncome)){
    params["largeItemIncomeThreshold"] = largeItemIncome[0].transactionThreshold;
  }

  if(!isEmpty(largeItemExpense)){
    params["largeItemExpenseThreshold"] = largeItemExpense[0].transactionThreshold;
  }

  return params;
}