import React from "react";
import {
  isEmpty, TextInput,
  useDataProvider
} from "react-admin";
import {
  emailWithSpacesValidation,
  maxLengthValidation,
  requiredValidation
} from "../../utils/util";
import {canFieldBeHidden, filterFieldBasedOnName} from "../../utils/CreateReportUtils";
import TextInputWithCharCount from "../common/TextInputWithCharCount";
import Divider from "@mui/material/Divider";
import ReportTypeAndDynamicTimeRange from "./ReportTypeAndDateRangeForCreateReportGrid";
import IncomeDynamicFields from "./IncomeVerificationForCreateReportGrid";
import CurrencyAndAmountDynamicFields from "./CurrencyAndATPForCreateReportGrid";
import SurveyDynamicFields from "./SurveyForCreateReportGrid";
import SurveyATPDynamicField from "./SurveyATPForCreateReportGrid";
import InsightsDynamicField from "./InsightsForCreateReportGrid";
import {LargeItemDynamicInput} from "./LargeItemDynamicInput";
import {Grid} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import {padding2, textColor, width} from "../Styles";

export const CreateElementsPrimaryReportGrid = (props) => {
  const [clientSurveys, setClientSurveys] = React.useState([]);
  const [clientSurveysError, setClientSurveysError] = React.useState();
  const dataProvider = useDataProvider();


  const firstLevelFields = props.fields.filter(field => isEmpty(field.dependentOn)).sort((a, b) => a.position - b.position);
  let components = [];

  const frequencyField = filterFieldBasedOnName("frequency", props.fields)
  const timeRangeField = filterFieldBasedOnName("timeRange", props.fields)
  const currencyField = filterFieldBasedOnName("amountCurrency", props.fields)
  const amountField = filterFieldBasedOnName("amountToProve", props.fields)
  const surveyAmountField = filterFieldBasedOnName("surveyAmountToProve", props.fields)
  const sourceOfFundsSurveyField = filterFieldBasedOnName("sourceOfFundsSurvey", props.fields)
  const factFindSurveyField = filterFieldBasedOnName("factFindSurvey", props.fields)
  const insightsField = filterFieldBasedOnName("insights", props.fields)
  const incomeMandatoryField = filterFieldBasedOnName("incomeMandatory", props.fields)
  const payslipVerificationField = filterFieldBasedOnName("payslipVerification", props.fields)
  const followUpDataRangeField = filterFieldBasedOnName("followUpDataRange", props.fields)
  const largeItemField = filterFieldBasedOnName("largeItem", props.fields)

  React.useEffect(() => {
    async function getSurveys() {
      dataProvider
        .getList('survey')
        .then(async ({ data }) => {
          setClientSurveys(data);
        })
        .catch((error) => {
          setClientSurveysError(error);
        });
    }
    getSurveys()
  }, [dataProvider]);

  firstLevelFields.forEach((field) => {
    if (field.type === 'Textfield') {
      const item = (
        <ListItemComponentForReport
          source={field.name}
          title={field.displayName}
          name={field.name}
          required={field.required}
          label={field.placeholder}
          multiline={field.multiline}
          defaultValue={field.defaultValue}
          hidden={field.isHidden}
          validate={[
            field.required && requiredValidation,
            field.name === 'emailAddress' && emailWithSpacesValidation,
          ]}
        />
      );
      components.push(item);
    } else if (field.type === 'TextfieldWithCharCount') {
      const item = (
        <React.Fragment>
          <TextInputWithCharCount
            title={field.displayName}
            source={field.name}
            required={field.required}
            name={field.name}
            label={field.placeholder}
            multiline={true}
            hidden={field.isHidden}
            validate={[field.required && requiredValidation, maxLengthValidation(250)]}
            defaultValue={field.defaultValue}
            maxLength={250}
          />
          <Divider light />
        </React.Fragment>
      );
      components.push(item);
    } else if (field.type === 'Dropdown' && field.name === 'reportTypes') {
      const item = (
        <React.Fragment>
          <ReportTypeAndDynamicTimeRange
            reportField={field}
            frequencyField={frequencyField}
            timeRangeField={timeRangeField}
            selectedTemplateId={props.selectedTemplateId}
          />
          <IncomeDynamicFields
            selectedTemplateId={props.selectedTemplateId}
            payslipVerificationField={payslipVerificationField}
            followUpDataRangeField={followUpDataRangeField}
          />
          <CurrencyAndAmountDynamicFields currencyField={currencyField} amountField={amountField} selectedTemplateId={props.selectedTemplateId} />
          <SurveyDynamicFields
            sourceOfFundsSurveyField={sourceOfFundsSurveyField}
            factFindSurveyField={factFindSurveyField}
            selectedTemplateId={props.selectedTemplateId}
            clientSurveys={clientSurveys}
            clientSurveysError={clientSurveysError}
          />
          <SurveyATPDynamicField surveyAmountField={surveyAmountField} selectedTemplateId={props.selectedTemplateId} />
          <InsightsDynamicField
            selectedTemplateId={props.selectedTemplateId}
            insightsField={insightsField}
            incomeMandatoryField={incomeMandatoryField}
          />
          <LargeItemDynamicInput largeItemField={largeItemField} selectedTemplateId={props.selectedTemplateId} />
        </React.Fragment>
      );
      components.push(item);
    }
  });
  return components;
};

const ListItemComponentForReport = (props) => {
  return (
    <React.Fragment>
      <Grid sx={{
        ...canFieldBeHidden(
          props.hidden,
          props.required,
          props.defaultValue
        ) ? { display: "none" } : {}
      }}>
        <ListItem sx={padding2.y}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} sx={textColor.gray}>
              {props.title}
              {props.required && <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>}
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <TextInput
                source={props.source}
                name={props.name}
                label={props.label}
                variant="outlined"
                size="small"
                sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
                multiline={props.multiline}
                defaultValue={props.defaultValue}
                validate={props.validate}
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider light />
      </Grid>
    </React.Fragment>
  );
};