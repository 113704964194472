import { CLIENT_SUSPENDED, LOCAL_STORAGE_KEYS, TemplateType } from "../components/Constants";
import { httpClient } from "../components/DataProvider";
import keycloak from "../keycloak";

// sets reportScheduling flag and clientId
export const getClientDetails = async () => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const clientDetails = `${apiUrl}/api/user/client/details`;
  await httpClient(clientDetails, { method: "GET" })
    .then(({ json }) => {
      localStorage.setItem("reportScheduling", json.data.reportScheduling)
      // setting clientId as well, will be used to fetch report templates on report screen
      localStorage.setItem("clientId", json.data.clientId)
      localStorage.setItem("insightsPermission", json.data.insightsPermission)
      localStorage.setItem("enabledFeatures", json.data.enabledFeatures)            
    })
    .catch((error) => {
      ( error?.body?.errors[0]?.code === CLIENT_SUSPENDED.ERROR_CODE && CLIENT_SUSPENDED.ERROR_MESSAGE === error?.body?.errors[0]?.description) && localStorage.setItem(LOCAL_STORAGE_KEYS.CLIENT_SUSPENDED, "true");   
      localStorage.setItem("reportScheduling", "false")
    });
};

// sets client default values
export const getClientDefaults = async () => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const clientDefaultsUrl = `${apiUrl}/api/client/client-defaults`;
  await httpClient(
    clientDefaultsUrl, {
    method: "GET",
  }).then(({json}) => {
      localStorage.setItem("clientDefaults", JSON.stringify(json.data));
    })
    .catch((error) => {
      localStorage.setItem("clientDefaults", JSON.stringify([]));
    });
};

//sets allowed templates for reporting screen
export const getReportTemplates = async () => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  // fethcing default template
  const defaultTemplateEndpoint = `${apiUrl}/template/default`;
  await httpClient(defaultTemplateEndpoint, { method: "GET" })
  .then(({ json }) => {
    localStorage.setItem("defaultTemplates", JSON.stringify(json.data))
  })
  .catch((error) => console.log(error));
  // fethcing allowed templates for client
  const clientTemplatesEndpoint = `${apiUrl}/template/client/${localStorage.getItem("clientId")}`;
  await httpClient(clientTemplatesEndpoint, { method: "GET" })
  .then(({ json }) => {
    const reportTemplates = []
    const secondReportTemplates = []
    if(json.data){
      json.data.forEach(item => {
        let choice = {}
        choice.id = item.templateId
        choice.name = item.templateName
        if(item.templateType === TemplateType.CREATE_REPORT_TEMPLATE)
          reportTemplates.push(choice)
        else if(item.templateType === TemplateType.EXPAND_REPORT_TEMPLATE)
          secondReportTemplates.push(choice)
      })
    }
    localStorage.setItem("reportTemplates", JSON.stringify(reportTemplates));
    localStorage.setItem("secondReportTemplates", JSON.stringify(secondReportTemplates))
  })
  .catch((error) => localStorage.setItem("reportTemplates", []), localStorage.setItem("secondReportTemplates", []));
};

// clears local storage items
export const resetLocalStorage = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("clientId");
  localStorage.removeItem("userRoles");
  localStorage.removeItem("loggedInUserId");
  localStorage.removeItem("reportScheduling");
  localStorage.removeItem("reportTemplates");
  localStorage.removeItem("defaultTemplates");
  localStorage.removeItem("insightsPermission");
  localStorage.removeItem("enabledFeatures");
  localStorage.removeItem("clientDefaults");
  localStorage.removeItem(LOCAL_STORAGE_KEYS.CLIENT_SUSPENDED);
};

export const resetStorageAndLogout = () => {
  resetLocalStorage();
  keycloak.logout();
}

export const checkTokenExpiryAndRefresh = async () => {
  if(keycloak.isTokenExpired()){
    await keycloak
      .updateToken(5)
      .success((refreshed) => {
        // if refresh token is still valid, will refresh the token after 5 seconds (default)
        if (refreshed) {
          localStorage.removeItem('auth');
          localStorage.removeItem('userRoles');
          localStorage.removeItem('loggedInUserId');
          localStorage.setItem('auth', 'Bearer '.concat(keycloak.token));
          localStorage.setItem(
            'userRoles',
            JSON.parse(atob(keycloak.token.split('.')[1])).realm_access.roles //finding user_roles from token
          );
          localStorage.setItem("loggedInUserId", keycloak.subject.substring(keycloak.subject.lastIndexOf(':') + 1)) //storing loggedIn userId
        } else {
          resetStorageAndLogout();
        }
      })
      .error(function () {
        alert('Due to inactivity your session has expired, please login again.');
        resetStorageAndLogout();
      });
  }
}